// Variables
@import "../../../sass/variables";

main {
	background-color: #F9F9F9;
}

.page-title {
	margin-bottom: 30px;
}

.content-place {
	padding: 30px 50px 10px;
	margin-bottom: 30px;
	border: 1px solid $theme-accent-white;
	border-radius: 10px;
	box-shadow: 0 10px 15px 0 $shadow;
	background-color: $theme-main-white;

	.common-btn {
		display: block;
		margin: 30px auto 40px;
	}

	.line-btn {
		background-color: $theme-accent-lightgreen;
		margin: 0 10px 10px;
		width: 30%;
	}

	.section-title {
		font-size: 1.1rem;
		color: $theme-accent-black;
	}

	.content-left {
		.label-text {
			margin-top: 15px;
			margin-bottom: 0;
			font-size: 1rem;
		}

		.password-reset {
			color: $theme-accent-black;
			text-decoration: underline;
		}

		.login-check {
			text-align: center;
			margin-top: 20px;

			label {
				margin: 0;
			}
		}
	}

	.content-right {
		padding-left: 30px;

		.description {
			margin-top: 15px;
			display: inline-block;
			font-size: 1rem;
		}
	}
}

// タブレット対応
@media (min-width: 768px) and (max-width: 1079px) {
	.content-place {
		padding: 30px 30px 10px;
	}
}


// スマートフォン対応
@media (max-width: 767px) {
	.page-title {
		margin-left: 10px;
	}

	.content-place {
		padding: 30px 20px 10px;

		.row {
			margin: auto;
		}

		.content-left {
			padding-right: 0;
			padding-left: 0;
			border-right: none;
			border-bottom: 1px solid $theme-main-lightgray;
		}

		.content-right {
			padding-right: 0;
			padding-left: 0;
			margin-top: 40px;
		}

		label:first-child {
			margin-top: 0;
		}

		.line-btn {
			width: 60%;
		}
	}
}
